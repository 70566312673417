export const FETCH_TEMP_USERS_REQUEST = 'FETCH_TEMP_USERS_REQUEST';
export const FETCH_TEMP_USERS_SUCCESS = 'FETCH_TEMP_USERS_SUCCESS';
export const FETCH_TEMP_USERS_FAILURE = 'FETCH_TEMP_USERS_FAILURE';

export const FETCH_TEMP_USER_DETAILS_REQUEST = 'FETCH_TEMP_USER_DETAILS_REQUEST';
export const FETCH_TEMP_USER_DETAILS_SUCCESS = 'FETCH_TEMP_USER_DETAILS_SUCCESS';
export const FETCH_TEMP_USER_DETAILS_FAILURE = 'FETCH_TEMP_USER_DETAILS_FAILURE';

export const ADD_TEMP_USER_REQUEST = 'ADD_TEMP_USER_REQUEST';
export const ADD_TEMP_USER_SUCCESS = 'ADD_TEMP_USER_SUCCESS';
export const ADD_TEMP_USER_FAILURE = 'ADD_TEMP_USER_FAILURE';