import React from 'react';

// ROUTER
import { BrowserRouter } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import { store } from './redux/store';

import { RouterConfig } from './navigation/RouterConfig';
import { userAbility } from './components/CASL/abilities';
import AbilityContext from './components/CASL/AbilityContext';

import './styles/bootstrap.min.css';
import './styles/style.css';
import './styles/loader.css';
import './styles/common.css';

const App = () => (
    <div>
        <AbilityContext.Provider value={userAbility}>
            <Provider store={store}>
                <BrowserRouter>
                    <RouterConfig />
                </BrowserRouter>
            </Provider>
        </AbilityContext.Provider>
    </div>
);

export default App;