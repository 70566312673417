/**
 * We purposely added 2 reducers for the example of combineReducers method.
 * There can be only 1 or even more than 2 reducers.
 * combineReducers defines the structure of the store object.
 */
import { combineReducers } from 'redux';
import tempReducer from './temp/reducer';
import tempUserReducer from './tempUser/reducer';
import userReducer from './user/reducer';

const reducer = combineReducers({
    temp: tempReducer,
    tempUser: tempUserReducer,
    users: userReducer
});

export default reducer;