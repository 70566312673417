import { Ability } from '@casl/ability';

let ANONYMOUS_ABILITY;

function defineAbility() {
    const permissions = JSON.parse(localStorage.getItem('userPermissions'));
    if (permissions) {
        return new Ability(permissions);
    }

    ANONYMOUS_ABILITY = ANONYMOUS_ABILITY || new Ability([]);
    return ANONYMOUS_ABILITY;
}

export const userAbility = defineAbility();