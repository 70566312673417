export const msalConfig = {
    auth: {
        clientId: import.meta.env.VITE_APP_NODE_ENV === 'production' ? '2f98a6c0-908a-43b6-b140-a98381e64e58' : 'e4fe35b1-3437-457b-b70c-3afd1e41e758',
        authority: import.meta.env.VITE_APP_NODE_ENV === 'production' ? 'https://login.microsoftonline.com/68283f3b-8487-4c86-adb3-a5228f18b893' : 'https://login.microsoftonline.com/600fc84d-b45f-4cc5-8ea4-63619872e13f', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: '/login',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const msGraphScopes = {
    scopes: ['User.Read']
};

export const apiScopes = {
    scopes: import.meta.env.VITE_APP_NODE_ENV === 'production' ? ['api://36bfe50e-97d3-4d48-bb17-afedbd268682/api.access'] : ['api://ac609d96-9db2-4b4c-a616-8d43546835d0/api.access']
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
// export const graphConfig = {
//     graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me'
// };