export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const BULK_UPLOAD_REQUEST = 'BULK_UPLOAD_REQUEST';
export const BULK_UPLOAD_SUCCESS = 'BULK_UPLOAD_SUCCESS';
export const BULK_UPLOAD_FAILURE = 'BULK_UPLOAD_FAILURE';

export const GET_ACTIVE_TAB = 'GET_ACTIVE_TAB';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const GET_LISTING_TEXT = 'GET_LISTING_TEXT';
export const SET_LISTING_TEXT = 'SET_LISTING_TEXT';

export const GET_ACTIVATE_SHOW_NOTIFICATION = 'GET_ACTIVATE_SHOW_NOTIFICATION';
export const SET_ACTIVATE_SHOW_NOTIFICATION = 'SET_ACTIVATE_SHOW_NOTIFICATION';
export const GET_NOTIFICATION_MESSAGE_TEXT = 'GET_NOTIFICATION_MESSAGE_TEXT';
export const SET_NOTIFICATION_MESSAGE_TEXT = 'SET_NOTIFICATION_MESSAGE_TEXT';

export const GET_ACTIVE_TAB_PAYEE = 'GET_ACTIVE_TAB_PAYEE';
export const SET_ACTIVE_TAB_PAYEE = 'SET_ACTIVE_TAB_PAYEE';

export const GET_SELECTED_ACTION_TAB = 'GET_SELECTED_ACTION_TAB';
export const SET_SELECTED_ACTION_TAB = 'SET_SELECTED_ACTION_TAB';

export const GET_TRANSFILE_TAB = 'GET_TRANSFILE_TAB';
export const SET_TRANSFILE_TAB = 'SET_TRANSFILE_TAB';

export const GET_REFFILE_TAB = 'GET_REFFILE_TAB';
export const SET_REFFILE_TAB = 'SET_REFFILE_TAB';

export const GET_CLAIM_TAB_CA = 'GET_CLAIM_TAB_CA';
export const SET_CLAIM_TAB_CA = 'SET_CLAIM_TAB_CA';

export const GET_PAYEETARGET_TAB = 'GET_PAYEETARGET_TAB';
export const SET_PAYEETARGET_TAB = 'SET_PAYEETARGET_TAB';