// Actions
export const ACCESS = 'access';
export const CREATE = 'create';
export const READ = 'read';
export const UPDATE = 'update';
export const DELETE = 'delete';
export const WITHDRAW = 'withdraw';
export const APPROVE = 'approve';
export const DISCARD = 'discard';

// Subjects
export const CONFIGURATION = 'configuration';
export const REFERENCE_FILE_TYPE = 'referenceFileType';
export const FORMCUSTOMISATION = 'formCustomisation';
export const RAW_CUSTOMISATION = 'rawCustomisation';
export const CHANNEL_HIERARCHY = 'channelHierarchy';

export const MASTER_DATA = 'masterData';
export const PAYEE = 'payee';
export const USER = 'user';
export const REFERENCE_FILE = 'referenceFile';
export const TRANSACTIONAL_FILE = 'transactionalFile';
export const TRANSACTIONAL_FILE_TYPE = 'transactionalFileType';

export const COMMISSION = 'commission';
export const RAW_DATA_BATCH = 'rawDataBatch';
export const CALCULATION = 'calculation';
export const CALCULATION_BATCH = 'calculationBatch';

export const MY_APPROVAL = 'myApproval';
export const DELEGATION = 'delegation';

export const WORKFLOWS = 'workflow';
export const PAYEE_DATA = 'payeeData';

export const CLAIM = 'claim';
export const MANAGER = 'Manager';
export const COMMISSION_ANALYST = 'Commission Analyst';
export const SYSTEM_ANALYST = 'System Analyst';
export const PAYEE_ROLE = 'Payee';
export const COMMISSION_TYPE = 'commissionsType';

export const SCHEME_DOCUMENT = 'schemeDocument';
export const TARGET = 'target';