import React from 'react';

const Loader = () => (
    <div className="loading">
        <div>
            This might take a few seconds…
        </div>
    </div>
);

export default Loader;