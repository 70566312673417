import {
    FETCH_TEMP_USERS_REQUEST,
    FETCH_TEMP_USERS_SUCCESS,
    FETCH_TEMP_USERS_FAILURE,
    FETCH_TEMP_USER_DETAILS_REQUEST,
    FETCH_TEMP_USER_DETAILS_SUCCESS,
    FETCH_TEMP_USER_DETAILS_FAILURE,
    ADD_TEMP_USER_REQUEST,
    ADD_TEMP_USER_SUCCESS,
    ADD_TEMP_USER_FAILURE
} from './types';

const initialState = {
    loading: false,
    tempUsers: [],
    error: '',
    tempUserLoading: false,
    tempUser: '',
    tempUserError: '',
    newTempUserLoading: false,
    newTempUser: '',
    newTempUserError: ''
};

const tempUserReducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_TEMP_USERS_REQUEST:
        return {
            ...state,
            loading: true
        };
    case FETCH_TEMP_USERS_SUCCESS:
        return {
            ...state,
            loading: false,
            tempUsers: action.payload,
            error: ''
        };
    case FETCH_TEMP_USERS_FAILURE:
        return {
            ...state,
            loading: false,
            tempUsers: [],
            error: action.payload
        };
    case FETCH_TEMP_USER_DETAILS_REQUEST:
        return {
            ...state,
            tempUserLoading: true
        };
    case FETCH_TEMP_USER_DETAILS_SUCCESS:
        return {
            ...state,
            tempUserLoading: false,
            tempUser: action.payload,
            tempUserError: ''
        };
    case FETCH_TEMP_USER_DETAILS_FAILURE:
        return {
            ...state,
            tempUserLoading: false,
            tempUser: '',
            tempUserError: action.payload
        };
    case ADD_TEMP_USER_REQUEST:
        return {
            ...state,
            newTempUserLoading: true
        };
    case ADD_TEMP_USER_SUCCESS:
        return {
            ...state,
            newTempUserLoading: false,
            newTempUser: action.payload,
            newTempUserError: ''
        };
    case ADD_TEMP_USER_FAILURE:
        return {
            ...state,
            newTempUserLoading: false,
            newTempUser: '',
            newTempUserError: action.payload
        };
    default: return state;
    }
};

export default tempUserReducer;