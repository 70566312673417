import { INCREMENT, DECREMENT } from './types';

const initialState = {
    counter: 0
};

const tempReducer = (state = initialState, action) => {
    switch (action.type) {
    case INCREMENT:
        return {
            ...state,
            counter: state.counter + (action.payload || 1)
        };
    case DECREMENT:
        return {
            ...state,
            counter: state.counter - 1
        };
    default:
        return state;
    }
};

export default tempReducer;