import {
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE,
    SEARCH_USER_REQUEST,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILURE,
    EDIT_USER_REQUEST,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILURE,
    BULK_UPLOAD_REQUEST,
    BULK_UPLOAD_SUCCESS,
    BULK_UPLOAD_FAILURE,
    GET_ACTIVE_TAB,
    SET_ACTIVE_TAB,
    GET_LISTING_TEXT,
    SET_LISTING_TEXT,
    GET_ACTIVATE_SHOW_NOTIFICATION,
    SET_ACTIVATE_SHOW_NOTIFICATION,
    GET_NOTIFICATION_MESSAGE_TEXT,
    SET_NOTIFICATION_MESSAGE_TEXT,
    GET_ACTIVE_TAB_PAYEE,
    SET_ACTIVE_TAB_PAYEE,
    GET_SELECTED_ACTION_TAB,
    SET_SELECTED_ACTION_TAB,
    GET_TRANSFILE_TAB,
    SET_TRANSFILE_TAB,
    GET_REFFILE_TAB,
    SET_REFFILE_TAB,
    GET_CLAIM_TAB_CA,
    SET_CLAIM_TAB_CA,
    GET_PAYEETARGET_TAB,
    SET_PAYEETARGET_TAB
} from './types';

const initialState = {
    loading: false,
    users: [],
    error: '',
    activeTab: 'channels',
    activeTabPayee: 'Payees',
    listingText: 'Users',
    activateShowNotification: false,
    notificationMessageText: '',
    selectedActionTab: 'Pending',
    transfileTab: 'Transactional files',
    reffileTab: 'Reference files',
    claimTabCA: 'All claims',
    payeeTargetTab: 'Targets'
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
    case ADD_USER_REQUEST:
    case SEARCH_USER_REQUEST:
    case EDIT_USER_REQUEST:
    case BULK_UPLOAD_REQUEST:
        return {
            ...state,
            loading: true
        };
    case ADD_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            users: [...state.users, action.payload],
            error: ''
        };
    case ADD_USER_FAILURE:
    case SEARCH_USER_FAILURE:
    case EDIT_USER_FAILURE:
    case BULK_UPLOAD_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload
        };
    case SEARCH_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            users: action.payload,
            userCount: action.userCount,
            error: ''
        };
    case EDIT_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            users: state.users.filter(user => (user.id === action.payload.id
                ? action.payload : user)),
            error: ''
        };
    case BULK_UPLOAD_SUCCESS:
        return {
            ...state,
            loading: false,
            users: state.users.map(user => action.payload.find(el => el.id === user.id) || user),
            error: ''
        };

    case GET_ACTIVE_TAB:
    case GET_LISTING_TEXT:
    case GET_ACTIVATE_SHOW_NOTIFICATION:
    case GET_NOTIFICATION_MESSAGE_TEXT:
    case GET_ACTIVE_TAB_PAYEE:
    case GET_SELECTED_ACTION_TAB:
    case GET_TRANSFILE_TAB:
    case GET_REFFILE_TAB:
    case GET_CLAIM_TAB_CA:
    case GET_PAYEETARGET_TAB:
        return {
            ...state
        };
    case SET_ACTIVE_TAB:
        return {
            ...state,
            activeTab: action.payload
        };
    case SET_LISTING_TEXT:
        return {
            ...state,
            listingText: action.payload
        };
    case SET_ACTIVATE_SHOW_NOTIFICATION:
        return {
            ...state,
            activateShowNotification: action.payload
        };
    case SET_NOTIFICATION_MESSAGE_TEXT:
        return {
            ...state,
            notificationMessageText: action.payload
        };
    case SET_ACTIVE_TAB_PAYEE:
        return {
            ...state,
            activeTabPayee: action.payload
        };
    case SET_SELECTED_ACTION_TAB:
        return {
            ...state,
            selectedActionTab: action.payload
        };
    case SET_TRANSFILE_TAB:
        return {
            ...state,
            transfileTab: action.payload
        };
    case SET_REFFILE_TAB:
        return {
            ...state,
            reffileTab: action.payload
        };
    case SET_CLAIM_TAB_CA:
        return {
            ...state,
            claimTabCA: action.payload
        };
    case SET_PAYEETARGET_TAB:
        return {
            ...state,
            payeeTargetTab: action.payload
        };
    default: return state;
    }
};

export default userReducer;